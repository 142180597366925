import * as _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Graph } from 'modules/graph';
import scssVars from 'helpers/scss-var-mapper';

class BarGraph extends Graph {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._graphType = am4charts.XYChart;
        this._axes = config.axes;
        this._series = config.series;
        this.config = config;
    }

    generateAxes() {
        _.each(this._axes, axisConfig => {
            let axis = null;
            switch(axisConfig.position) {
                case 'top':
                case 'bottom':
                    if (axisConfig.rotate){
                        axis = this.graph.xAxes.push(new am4charts.ValueAxis());
                        if (axisConfig.show_percentage){
                            axis.min = 0;
                            axis.max = 100;
                            axis.strictMinMax = true;
                            axis.calculateTotals = true;
                        }
                    }
                    else {
                        axis = this.graph.xAxes.push(new am4charts.CategoryAxis());
                        axis.dataFields.category = axisConfig.category;
                        if (this.config.defaultDrillDownId == 5){
                            axis.renderer.cellStartLocation = 0.1
                            axis.renderer.cellEndLocation = 0.9
                        }

                        let label = axis.renderer.labels.template;
                        label.wrap = true;
                        label.maxWidth = 120;
                        label.textAlign = 'middle';

                        axis.events.on("sizechanged", function(ev) {
                            let in_axis = ev.target;
                            if (!in_axis.renderer.labels.template.rotation){
                                if (!in_axis.rotate){
                                    let cellWidth = in_axis.pixelWidth / (in_axis.endIndex - in_axis.startIndex);
                                    if (cellWidth < 100){
                                        cellWidth = 100;   
                                    }
                                    in_axis.renderer.labels.template.maxWidth = cellWidth;
                                }
                            }
                        });

                    }
                    
                    if(axisConfig.position == 'top') {
                        axis.renderer.opposite = true;
                    }
                    break;

                case 'left':
                case 'right':
                    if (axisConfig.rotate){
                        axis = this.graph.yAxes.push(new am4charts.CategoryAxis());
                        axis.dataFields.category = axisConfig.category;


                        let label = axis.renderer.labels.template;
                        label.wrap = true;
                        label.maxWidth = 200; 
                        label.textAlign = 'end';
                        label.fontSize = '14px';

                        axis.events.on("sizechanged", function(ev) {
                            let in_axis = ev.target;
                            if (!in_axis.renderer.labels.template.rotation){
                                if (!in_axis.rotate){
                                    let cellWidth = in_axis.pixelWidth / (in_axis.endIndex - in_axis.startIndex);
                                    if (cellWidth < 175){
                                        cellWidth = 175;   
                                    }
                                    in_axis.renderer.labels.template.maxWidth = cellWidth;
                                }
                            }
                        });

                        axis.renderer.minGridDistance = 25;

                    }
                    else {
                        axis = this.graph.yAxes.push(new am4charts.ValueAxis());
                        if (axisConfig.show_percentage){
                            axis.min = 0;
                            axis.max = 100;
                            axis.strictMinMax = true;
                            axis.calculateTotals = true;
                        }
                    }
                    if(axisConfig.position == 'right') {
                        axis.renderer.opposite = true;
                    }
                    break;
            }

            axis.id = axisConfig.id;
            axis.title.text = axisConfig.label;
            axis.tooltip.disabled = true;

            //axis.renderer.grid.template.disabled = true;
            
            this.axes[axis.id] = axis;
        });

    }

    generateSeries() {
        _.each(this._series, seriesConfig => {
            let series = null;
            switch(seriesConfig.type) {
                case 'column':
                    series = this.graph.series.push(new am4charts.ColumnSeries());
                    break;

                case 'line':
                    series = this.graph.series.push(new am4charts.LineSeries());
                    break;
            }

            let percent_symbol = '';
            if (seriesConfig.data.measure_type == 'percent'){
                percent_symbol = '%';
            }

            series.id = seriesConfig.id;
            series.name = seriesConfig.label;
            series.displayname = seriesConfig.label || seriesConfig.data.value;


            series.tooltipText = "{categoryX}: {formatted_size_value.formatNumber('#,###.')}";
            series.tooltip.pointerOrientation = 'vertical';

            if (seriesConfig.rotate){
                series.dataFields.valueX = seriesConfig.data.value;
                series.dataFields.formatted_size_value = seriesConfig.data.value;

                series.columns.template.adapter.add("fill", (fill, target) => {
                    return target.dataItem.categoryY == 'Totale' ? scssVars.palette['graph-yellow'] : fill;
                });

                if (this.config.numOfDecimals == 0){
                    series.tooltipText = "{categoryY}:{formatted_size_value.formatNumber('#,###.')}" + percent_symbol;
                }
                else if (this.config.numOfDecimals == 1){
                    series.tooltipText = "{categoryY}:{formatted_size_value.formatNumber('#,###.0')}" + percent_symbol;
                }
                else if (this.config.numOfDecimals == 2){
                    series.tooltipText = "{categoryY}:{formatted_size_value.formatNumber('#,###.00')}" + percent_symbol;
                }
                else {
                    series.tooltipText = "{categoryY}:{formatted_size_value.formatNumber('#,###.0')}"+ percent_symbol;
                }


                if (seriesConfig.show_percentage){
                    series.dataFields.valueXShow = "totalPercent";
                    if (this.config.numOfDecimals == 0){
                        series.tooltipText = "{categoryY}:{valueX.totalPercent.formatNumber('#,###.')}%";
                    }
                    else if (this.config.numOfDecimals == 1){
                        series.tooltipText = "{categoryY}:{valueX.totalPercent.formatNumber('#,###.0')}%";
                    }
                    else if (this.config.numOfDecimals == 2){
                        series.tooltipText = "{categoryY}:{valueX.totalPercent.formatNumber('#,###.00')}%";
                    }
                    else {
                        series.tooltipText = "{categoryY}:{valueX.totalPercent.formatNumber('#,###.0')}%";
                    }
                }
                series.dataFields.categoryY = seriesConfig.data.category;
                series.dataFields.openValueX = "open";

                series.tooltip.pointerOrientation = 'vertical';

                if (!this.config.hide_x_axes_labels){
                    series.xAxis.title.text = seriesConfig.data.display_key;
                }
            }
            else {
                series.dataFields.valueY = seriesConfig.data.value;
                series.dataFields.formatted_size_value = seriesConfig.data.value;
                if (seriesConfig.show_percentage){
                    series.dataFields.valueYShow = "totalPercent";
                    if (this.config.numOfDecimals == 0){
                        series.tooltipText = "{categoryX}:{valueY.totalPercent.formatNumber('#,###.')}%";
                    }
                    else if (this.config.numOfDecimals == 1){
                        series.tooltipText = "{categoryX}:{valueY.totalPercent.formatNumber('#,###.0')}%";
                    }
                    else if (this.config.numOfDecimals == 2){
                        series.tooltipText = "{categoryX}:{valueY.totalPercent.formatNumber('#,###.00')}%";
                    }
                    else {
                        series.tooltipText = "{categoryX}:{valueY.totalPercent.formatNumber('#,###.0')}%";
                    }
                }
                else {
                    if (seriesConfig.use_standard_display == true){
                    }
                    else {
                        series.dataFields.formatted_size_value = seriesConfig.data.value;
                        if (this.config.numOfDecimals == 0){
                            series.tooltipText = "{categoryX}: {displayValue.formatNumber('#,###.')}" + percent_symbol;
                        }
                        else if (this.config.numOfDecimals == 1){
                            series.tooltipText = "{categoryX}: {displayValue.formatNumber('#,###.0')}" + percent_symbol;
                        }
                        else if (this.config.numOfDecimals == 2){
                            series.tooltipText = "{categoryX}: {displayValue.formatNumber('#,###.00')}" + percent_symbol;
                        }
                        else {
                            series.tooltipText = "{categoryX}: {displayValue.formatNumber('#,###.0')}" + percent_symbol;
                        }
                    }
                }
                series.dataFields.categoryX = seriesConfig.data.category;
                series.dataFields.openValueY = "open";
            }
            if (seriesConfig.stacked){
                series.dataFields.valueY = seriesConfig.data.value;
                series.dataFields.formatted_size_value = seriesConfig.data.value;
                if (seriesConfig.show_percentage){
                    series.dataFields.valueYShow = "totalPercent";
                    series.dataFields.valueYShow = "totalPercent";
                    if (this.config.numOfDecimals == 0){
                        series.tooltipText = "{displayname}: {valueY.totalPercent.formatNumber('#,###.')}%";
                    }
                    else if (this.config.numOfDecimals == 1){
                        series.tooltipText = "{displayname}: {valueY.totalPercent.formatNumber('#,###.0')}%";
                    }
                    else if (this.config.numOfDecimals == 2){
                        series.tooltipText = "{displayname}: {valueY.totalPercent.formatNumber('#,###.00')}%";
                    }
                    else {
                        series.tooltipText = "{displayname}: {valueY.totalPercent.formatNumber('#,###.0')}%";
                    }
                }
                series.dataFields.categoryX = seriesConfig.data.category;
                series.dataFields.openValueY = "open";
                series.stacked = true;
                series.tooltip.dy = -3;
                this.graph.chartContainer.paddingTop = 40;
            } else if(this._series.length > 1) {
                // Multi series graph
                series.dataFields.valueY = seriesConfig.data.value;
                series.dataFields.formatted_size_value = seriesConfig.data.value;
                if (seriesConfig.show_percentage){
                    if (this.config.numOfDecimals == 0){
                        series.tooltipText = "{categoryX}: {valueY.formatNumber('#,###.')}" + percent_symbol;
                    }
                    else if (this.config.numOfDecimals == 1){
                        series.tooltipText = "{categoryX}: {valueY.formatNumber('#,###.0')}" + percent_symbol;
                    }
                    else if (this.config.numOfDecimals == 2){
                        series.tooltipText = "{categoryX}: {valueY.formatNumber('#,###.00')}" + percent_symbol;
                    }
                    else {
                        series.tooltipText = "{categoryX}: {valueY.formatNumber('#,###.0')}" + percent_symbol;
                    }
                } else {
                    if (this.config.numOfDecimals == 0){
                        series.tooltipText = "{displayname}: {valueY.formatNumber('#,###.')}" + percent_symbol;
                    }
                    else if (this.config.numOfDecimals == 1){
                        series.tooltipText = "{displayname}: {valueY.formatNumber('#,###.0')}" + percent_symbol;
                    }
                    else if (this.config.numOfDecimals == 2){
                        series.tooltipText = "{displayname}: {valueY.formatNumber('#,###.00')}" + percent_symbol;
                    }
                    else {
                        series.tooltipText = "{displayname}: {valueY.formatNumber('#,###.0')}" + percent_symbol;
                    }
                }
            }

            series.legendSettings.labelText = "{displayname}";
            series.legendSettings.itemValueText = " ";

            if (seriesConfig.year_name) {
                series.year_name = seriesConfig.year_name;
                if (this.config.numOfDecimals == 0){
                    series.tooltipText = "{categoryX} {year_name}: {formatted_size_value.formatNumber('#,###.')}";
                }
                else if (this.config.numOfDecimals == 1){
                    series.tooltipText = "{categoryX} {year_name}: {formatted_size_value.formatNumber('#,###.0')}";
                }
                else if (this.config.numOfDecimals == 2){
                    series.tooltipText = "{categoryX} {year_name}: {formatted_size_value.formatNumber('#,###.00')}";
                }
                else {
                    series.tooltipText = "{categoryX} {year_name}: {formatted_size_value.formatNumber('#,###.0')}";
                }
            }
            if (seriesConfig.dx){
                //series.columns.template.width = am4core.percent(120);
                series.columns.template.dx = seriesConfig.dx; // TODO capire come mostrare bene
            }


            //console.log(series.valueY, series.valueYShow ,seriesConfig.label, seriesConfig.data.value, seriesConfig.data.category, seriesConfig.stacked)

            if(seriesConfig.axes != null) {
                series.xAxis = this.axes[seriesConfig.axes.x];
                series.yAxis = this.axes[seriesConfig.axes.y];

                if(this.config.hide_x_axes_labels) {
                    series.xAxis.renderer.labels.template.adapter.add("text", function(text, target) {
                        return "";
                    });
                }
                
                if(this.config.hide_y_axes_labels) {
                    series.yAxis.renderer.labels.template.adapter.add("text", function(text, target) {
                        return "";
                    });
                }
            }

            if (this._axes.length >= 3){ // asse bottom e due laterali, imposto titolo
                series.yAxis.title.text = seriesConfig.data.display_key;
            }

            //console.log(seriesConfig.label, seriesConfig.data.value, seriesConfig.data.category, seriesConfig.stacked)
            /*if (seriesConfig.type == 'line') {
                series.propertyFields.stroke = "color" + seriesConfig.data.value;
                series.propertyFields.fill = "color" + seriesConfig.data.value;
            }*/

            if(seriesConfig.multiColor == true) {
                series.columns.template.adapter.add('fill', (fill, target)=> {
                    return this.graph.colors.getIndex(target.dataItem.index);
                });
            }

            

            this.series[series.id] = series;
        });
    }

    generateCursor() {
        let cursor = new am4charts.XYCursor();
        this.graph.cursor = cursor;
    }
}

export { BarGraph }
