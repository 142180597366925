import * as request from 'superagent';
import * as _ from 'lodash';


class Widget {

    constructor(element, config, chartbox) {
        // PRIVATE PROPERTIES
        this._endpoint = '/bi/tree/';
        this._kpis = config.kpis;
        this._dashboardId = config.dashboardId;
        this._hideTitles = config.hide_titles;
        this._menuId = config.menuId;
        this._drillDown = config.defaultDrillDownId;
        this._containerConfig = config.container;
        this._filterParameters = config.filterParameters;
        this._emptyValues = [
            '',
            'None',
        ];
        this._emptyContent = '-';

        // PUBLIC PROPERTIES
        this.element = element;
        this.chartbox = chartbox;
        this.widget = {
            blocks: [],
            loading: false
        };
    }

    init() {
        this.widget.loading = true;
        this.fetchData();
    }

    setExtraConfig(extraConfig) {
        if(!_.isUndefined(extraConfig) && extraConfig.p_id) {
            this._period = extraConfig.p_id;
        }
    }

    render() {
        _.each(this.widget.blocks, (block, index) => {
            let blockElement = document.createElement('div');
            blockElement.classList.add('c-widget__block');
            if (this._hideTitles != true){
                let titleElement = document.createElement('h6');
                titleElement.classList.add('c-widget__title');
                if(this._emptyValues.includes(block.title)) {
                    titleElement.textContent = this._emptyContent;
                } else {
                    titleElement.textContent = block.title;
                }
                blockElement.appendChild(titleElement);
            }

            _.each(block.content, content => {
                blockElement.appendChild(this.renderContent(content));
            });
            this.element.appendChild(blockElement);

            if(index != this.widget.blocks.length - 1) {
                let gutterElement = document.createElement('div');
                gutterElement.classList.add('c-widget__gutter');
                this.element.appendChild(gutterElement);
            }
        });
        this.renderCallback();
    }

    renderContent(content) {
        let contentElement = document.createElement('div');
        contentElement.classList.add('c-widget__content');
        if (this._hideTitles != true){
            let titleElement = document.createElement('label');
            titleElement.innerHTML = content.title;
            contentElement.appendChild(titleElement);
        }
        _.each(content.values, value => {
            contentElement.appendChild(this.renderValue(value));
        });
        return contentElement;
    }

    renderValue(value) {
        let valueElement = document.createElement('span');
        valueElement.classList.add('c-widget__value');
        if(this._emptyValues.includes(value)) {
            valueElement.textContent = this._emptyContent;
        } else {
            valueElement.textContent = value;
        }
        return valueElement;
    }

    generateBlock(blockData) {
        return {
            id: blockData.id,
            title: blockData.title,
            order: this.widget.blocks.length + 1,
            kpis: [blockData.kpi],
            content: []
        };
    }

    generateContent(contentData) {
        return {
            title: contentData.title,
            kpi: contentData.kpi,
            values: []
        };
    }

    addBlock(blockData) {
        let block = _.find(this.widget.blocks, { id: blockData.id });
        if(_.isUndefined(block)) {
            block = this.generateBlock(blockData);
            this.widget.blocks.push(block);
        } else {
            block.kpis.push(blockData.kpi);
        }
        _.each(blockData.content, contentData => {
            this.addContent(contentData, block.id);
        });
    }

    addContent(contentData, blockId) {
        let block = _.find(this.widget.blocks, { id: blockId });
        if(!_.isUndefined(block)) {
            let content = _.find(block.content, { kpi: contentData.kpi });
            if(_.isUndefined(content)) {
                content = this.generateContent(contentData);
                block.content.push(content);
            }
            _.each(contentData.values, valueData => {
                this.addValue(valueData, content);
            });
        }
    }

    addValue(valueData, content) {
        content.values.push(valueData.value);
    }

    fetchData() {
        let requests = [];
        // PREPARE REQUESTS BASED ON KPI CONFIG
        _.each(this._kpis, kpi => {
            let params = {
                kpi: kpi.key,
                menu_id: this._menuId,
                from_who: 1,
                debug: window.agregg.debugMode
            };
            if(this._drillDown != undefined) {
                params['drill_down_id'] = this._drillDown;
            }
            if(this._period != undefined) {
                params['per'] = this._period;
            }
            let r = request.get(this._endpoint)
            .set('Accept', 'application/json')
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });
            requests.push(r);
        });
        // HANDLE REQUESTS RESPONSES
        Promise.all(requests).then(responses => {
            _.each(responses, blockData => {
                this.addBlock(blockData);
            });
            this.widget.loading = false;
            this.render();
        });
    }

    renderCallback() {
        if(this.chartbox) {
            this.chartbox.render();
        }
    }
}

export { Widget }