import * as _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import scssVars from 'helpers/scss-var-mapper';
import { Graph } from 'modules/graph';

class BulletGraph extends Graph {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._graphType = am4charts.XYChart;
        this._axes = config.axes;
        this._series = config.series;
        this.config = config;
    }

    generateAxes() {
        this.graph.paddingLeft = 10;
        this.graph.paddingRight = 10;

        _.each(this._axes, axisConfig => {
            let axis = null;
            let label = null;
            switch(axisConfig.position) {
                case 'bottom':
                    axis = this.graph.xAxes.push(new am4charts.ValueAxis());
                    axis.renderer.grid.template.disabled = true;
                    axis.min = 1;
                    axis.max = 7;
                    axis.strictMinMax = true;
                    axis.numberFormatter.numberFormat = "'Gruppo '#";
                    axis.renderer.baseGrid.disabled = true;
                    axis.renderer.grid.template.disabled = true;
                    axis.renderer.labels.template.disabled = true;
                    axis.integersOnly = true;

                    let colors = ["#FF5700", "#FF8C00", "#FFC100", "#FFF600", "#D4FF00", "#9FFF00", "#6AFF00"];
                    let gradient = new am4core.LinearGradient();
                    for (var i = axis.min; i <= axis.max; ++i) {
                        gradient.addColor(am4core.color(colors[i-1]));
                        let range = axis.axisRanges.create();
                        range.value = i;
                        range.label.text = "Gruppo " + (8-i);
                        range.grid.disabled = true;
                    }

                    let range = axis.axisRanges.create();
                    range.value = axis.min;
                    range.endValue = axis.max;
                    range.axisFill.fill = gradient;
                    range.axisFill.fillOpacity = 0.8;
                    range.label.disabled = true;
                    range.grid.disabled = true;

                    label = axis.renderer.labels.template;
                    label.wrap = true;
                    label.maxWidth = 200; 
                    label.textAlign = 'end';
                    label.fontSize = '14px';
                    label.rotation = -90;
                    label.horizontalCenter = "right";
                    label.verticalCenter = "middle";

                    break;

                case 'left':
                    axis = this.graph.yAxes.push(new am4charts.CategoryAxis());
                    axis.dataFields.category = axisConfig.category;
                    axis.renderer.grid.template.disabled = true;
                    axis.renderer.labels.template.disabled = true;
                    axis.renderer.ticks.template.disabled = true;

                    axis.strokeWidth = 0;
                    axis.minWidth = 0;
                    axis.minHeight = 0;
                    axis.marginLeft = 0;
                    axis.marginRight = 0;

                    break;
            }

            axis.id = axisConfig.id;
            axis.title.text = axisConfig.label;
            axis.tooltip.disabled = true;

            this.axes[axis.id] = axis;
       });
    }

    generateSeries() {
        _.each(this._series, seriesConfig => {
            // let series = this.graph.series.push(new am4charts.ColumnSeries());
            // series.id = seriesConfig.id;
            // series.name = seriesConfig.label;
            // // series.dataFields.valueX = seriesConfig.data.value;
            // series.dataFields.valueX = 'displayValue';
            // series.dataFields.categoryY = seriesConfig.data.category;
            // series.columns.template.fill = am4core.color(scssVars.palette['darkgrey']);
            // series.columns.template.height = 40;
            // series.tooltipText = "Gruppo {displayValue}";
            // series.tooltip.disabled = true;

            let series = this.graph.series.push(new am4charts.StepLineSeries());
            series.id = seriesConfig.id;
            series.name = seriesConfig.label;
            // series.dataFields.valueX = seriesConfig.data.value;
            series.dataFields.valueX = 'displayValue';
            series.dataFields.categoryY = seriesConfig.data.category;
            series.strokeWidth = 3;
            series.noRisers = true;
            series.startLocation = 0.20;
            series.endLocation = 0.80;

            series.stroke = am4core.color(scssVars.palette['darkgrey'])
            series.tooltipText = "Gruppo {displayValue}";
            series.tooltip.disabled = true;

            this.series[series.id] = series;
        });

        this.graph.events.on("beforedatavalidated", function(event) {
            let data = event.target.data;
            _.each(data, dataItem => {
                dataItem.displayValue = 8 - dataItem.displayValue;
            });
            event.target.data = data;
        });
    }

    generateLegend() { }
}

export { BulletGraph }
