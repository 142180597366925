import * as request from 'superagent';
import * as _ from 'lodash';
import { JsHelpers } from 'helpers/helpers';
import { Widget } from 'modules/widget';

class DetailWidget extends Widget {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._widgetType = 'detail';
        this._endpoint = '/bi/get_filter_item/1/';
        this._config = config;
    }

    render() {
        if(this.chartbox) {
            this.chartbox.render();
        }
    }

    fetchData() {
        let _this = this;
        _this.search();
    }

    search() {
        let _this = this;
        let requests = [];
        let params = {
            debug: window.agregg.debugMode
        };

        let r = request.post(this._endpoint)
            .set('Accept', 'application/json')
            .send(
                {"id": JsHelpers.gup('gff')[0] }
            ) 
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });
        requests.push(r);

        // HANDLE REQUESTS RESPONSES
        Promise.all(requests).then(responses => {

            let acceptablesKey = [];
            _.each(this._config.extra_conf.field, (val, field) => {
                let l = field.split('_');
                acceptablesKey.push('level_item_' + l[0] + '_attr_' + l[1]  )
            })

            _.each(responses, blockData => {
                let tableData = blockData.data;

                _this.element.classList.add('o-grid');

                let table_container = document.createElement('div');
                table_container.classList.add('o-grid__item');
                table_container.classList.add('c-detail-widget');
                table_container.classList.add('u-1/1');
                //table_container.classList.add('u-1/2@md');
                //table_container.classList.add('u-margin--right');
                //table_container.classList.add('u-margin--right');

                // let table_container2 = document.createElement('div');
                // table_container2.classList.add('o-grid__item');
                // table_container2.classList.add('c-detail-widget');
                // table_container2.classList.add('u-1/1');
                // table_container2.classList.add('u-1/2@md');

                let table = document.createElement('table');
                table.classList.add('c-table');
                table.width = '100%';
                
                
                let tableBody = document.createElement('tbody');
                tableBody.classList.add('c-table__body');

                let table2 = document.createElement('table');
                table2.classList.add('c-table');
                table2.classList.add('c-table--2');
                table2.width = '100%';
                //table2.classList.add('u-1/1');
                let tableBody2 = document.createElement('tbody');
                tableBody2.classList.add('c-table__body');

                tableData.forEach(function(rowData) {
                    
                    let json_attr = JSON.parse(rowData.attribute_json);
                    //console.log(json_attr);
                    document.getElementsByClassName('c-content__header__title')[0].classList.add('u-invisible');
                    let page_title = document.getElementsByClassName('c-content__header__title')[0].innerHTML;

                    //let halfWayThough = Math.ceil(acceptablesKey.length / 2)

                    //let arrayFirstHalf = acceptablesKey.slice(0, halfWayThough);
                    //let arraySecondHalf = acceptablesKey.slice(halfWayThough, acceptablesKey.length);

                    _this.create_single_detail_table(acceptablesKey, json_attr, _this, page_title, tableBody);
                    //_this.create_single_detail_table(arraySecondHalf, json_attr, _this, page_title, tableBody);
                    
                });

                table.appendChild(tableBody);
                table_container.appendChild(table);
                _this.element.appendChild(table_container);

                //table2.appendChild(tableBody2);
                //table_container2.appendChild(table2);
                //_this.element.appendChild(table_container2);
            });
            this.widget.loading = false;

            this.render();
        });
    }

    create_single_detail_table(arrayFirstHalf, json_attr, _this, page_title, tableBody) {

        let not_group_filter_item_id = _this._config.extra_conf['not_group_filter_item_id']; // 5056
        let star_not_grupped = _this._config.extra_conf['star_not_grupped']; //'level_item_52_attr_39'
        let star_grupped = _this._config.extra_conf['star_grupped']; //'level_item_52_attr_38'

        _.each(arrayFirstHalf, (attr, key) => {
            let cellData = json_attr[attr];
            
            let row = document.createElement('tr');
            row.classList.add('c-table__row--spaced');
            row.classList.add('c-table__row');

            let cellName = document.createElement('td');
            cellName.classList.add('c-table__cell');
            cellName.classList.add('c-table__cell--text');
            cellName.classList.add('c-table__cell--name');
            //cellName.style.width = "40%";

            let name = _this._config.extra_conf.field[attr.replace('level_item_', '').replace('attr_', '')];
            let other_page_title = page_title;
            if (page_title.indexOf(attr) > -1) {
                page_title = page_title.replace(attr, cellData);
                other_page_title = other_page_title.replace(attr, cellData).replace(star_grupped, '').replace(star_not_grupped, '');
                document.title = other_page_title;
                document.querySelector('.c-content__header__title').innerHTML = other_page_title;
                let breadcrumbsElement = document.querySelector('.c-breadcrumbs .is-active');
                if(breadcrumbsElement != null) {
                    breadcrumbsElement.innerHTML = other_page_title;
                }

                if (JsHelpers.gup('gff')[1] == not_group_filter_item_id){ //  not_group_filter_item_id
                    if (!json_attr[star_not_grupped]){
                        json_attr[star_not_grupped] = '';
                    }
                    page_title = page_title.replace(star_not_grupped, json_attr[star_not_grupped]);
                    page_title = page_title.replace(star_grupped, '');
                    if (!page_title) {
                        page_title = '';
                    }
                    document.querySelector('.c-content__header__title').innerHTML = page_title;
                }
                else {
                    if (!json_attr[star_grupped]){
                        json_attr[star_grupped] = '';
                    }
                    page_title = page_title.replace(star_grupped, json_attr[star_grupped]);
                    page_title = page_title.replace(star_not_grupped, '');
                    if (!page_title) {
                        page_title = '';
                    }
                    document.querySelector('.c-content__header__title').innerHTML = page_title;
                }

                document.getElementsByClassName('c-content__header__title')[0].classList.remove('u-invisible');
            }
            
            let e_div = document.createElement("div");
            e_div.classList.add('c-table__data');
            let e = document.createElement("span");
            let b = document.createElement("strong");
            e.appendChild(b);
            e_div.appendChild(e);

            b.innerHTML = name;
            cellName.appendChild(e_div);
            row.appendChild(cellName);
            let cell = document.createElement('td');
            cell.classList.add('c-table__cell');
            cell.classList.add('c-table__cell--text');
            let e2 = document.createElement("span");
            if (!cellData) {
                cellData = ' - ';
            }

            cellData = replaceAccents(cellData);
            e2.innerHTML = cellData;
            cell.appendChild(e2);
            row.appendChild(cell);
            tableBody.appendChild(row);

           function replaceAccents(str) {
             // Verifies if the String has accents and replace them
               str = str
                .replace(/\\xEC/ig, "ì")
                .replace(/\\xC0/ig, "À")
                .replace(/\\xC1/ig, "Á")
                .replace(/\\xC2/ig, "Â")
                .replace(/\\xC3/ig, "Ã")
                .replace(/\\xC4/ig, "Ä")
                .replace(/\\xC5/ig, "Å")
                .replace(/\\xC6/ig, "Æ")
                .replace(/\\xC7/ig, "Ç")
                .replace(/\\xC8/ig, "È")
                .replace(/\\xC9/ig, "É")
                .replace(/\\xCA/ig, "Ê")
                .replace(/\\xCB/ig, "Ë")
                .replace(/\\xCC/ig, "Ì")
                .replace(/\\xCD/ig, "Í")
                .replace(/\\xCE/ig, "Î")
                .replace(/\\xCF/ig, "Ï")
                .replace(/\\xD0/ig, "Ð")
                .replace(/\\xD1/ig, "Ñ")
                .replace(/\\xD2/ig, "Ò")
                .replace(/\\xD3/ig, "Ó")
                .replace(/\\xD4/ig, "Ô")
                .replace(/\\xD5/ig, "Õ")
                .replace(/\\xD6/ig, "Ö")
                .replace(/\\xD8/ig, "Ø")
                .replace(/\\xD9/ig, "Ù")
                .replace(/\\xDA/ig, "Ú")
                .replace(/\\xDB/ig, "Û")
                .replace(/\\xDC/ig, "Ü")
                .replace(/\\xDD/ig, "Ý")
                .replace(/\\xDE/ig, "Þ")
                .replace(/\\xDF/ig, "ß")
                .replace(/\\xE0/ig, "à")
                .replace(/\\xE1/ig, "á")
                .replace(/\\xE2/ig, "â")
                .replace(/\\xE3/ig, "ã")
                .replace(/\\xE4/ig, "ä")
                .replace(/\\xE5/ig, "å")
                .replace(/\\xE6/ig, "æ")
                .replace(/\\xE7/ig, "ç")
                .replace(/\\xE8/ig, "è")
                .replace(/\\xE9/ig, "é")
                .replace(/\\xEA/ig, "ê")
                .replace(/\\xEB/ig, "ë")
                .replace(/\\xEC/ig, "ì")
                .replace(/\\xED/ig, "í")
                .replace(/\\xEE/ig, "î")
                .replace(/\\xEF/ig, "ï")
                .replace(/\\xF0/ig, "ð")
                .replace(/\\xF1/ig, "ñ")
                .replace(/\\xF2/ig, "ò")
                .replace(/\\xF3/ig, "ó")
                .replace(/\\xF4/ig, "ô")
                .replace(/\\xF5/ig, "õ")
                .replace(/\\xF6/ig, "ö")
                .replace(/\\xF8/ig, "ø")
                .replace(/\\xF9/ig, "ù")
                .replace(/\\xFA/ig, "ú")
                .replace(/\\xFB/ig, "û")
                .replace(/\\xFC/ig, "ü")
                .replace(/\\xFD/ig, "ý")
                .replace(/\\xFE/ig, "þ")
                .replace(/\\xFF/ig, "ÿ")
             return str;
           }
           
        });
    }
}

export { DetailWidget }